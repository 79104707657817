.box_container {
    align-items: center;
    padding: 16px 0px;
    position: static;
    width: 100%;
    left: 0px;
    top: 64px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 24px 0px;
    max-height: 75vh;
    overflow-y: auto;
    -ms-overflow-style: none;
  scrollbar-width: none;
}
.box_container::-webkit-scrollbar {
    display: none;
}
.edit_text_container {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #383838;
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
}

.edit_box_container {
    display: flex;
}

.img_container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    position: relative;

}

.img_inner_container {
    height: 130px;
    width: 130px;
    padding-left: 0.5rem;
    position: relative;
}

.img_inner_container {
    height: 130px;
    width: 130px;
    padding-left: 0.5rem;
}

.userimg {
    height: 130px;
    width: 130px !important;
    border-radius: 8px;
}

.edit_icon_container {
    position: absolute;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 0.8rem;
    height: 6px;
    width: 6px;
    // bottom: 7%;
    top: 87%;
    left: 41%;
    border: 1px solid #E1E1E1;
}

.text_container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 1rem;
    width: 100%;

}

.inner_text_container {
    width: 50%;
}

.edit_container {
    width: 70%;
    margin-left: 2rem;
}

.heading_container {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #383838;
}

.text_field_container {
    margin-top: 1rem;
}

.sub_container {
    margin-top: 0.5rem;
}

.text_field {
    height: 40px !important;
    // min-width: 23rem;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #383838 !important;
    width: 100%;
}

.button_container {
    margin: 1rem;
    margin-top: -0.5rem;
    display: flex;
}

.cancel_btn {
    margin-right: 1rem;
    text-transform: capitalize !important;
    border-radius: 4px !important;
    color: #D9001D !important;
    text-align: center !important;
    background-color: #fff !important;
    border: 1px solid #D9001D !important;

    &:hover {
        background-color: #fff !important;
    }
}

.save_btn {
    width: 90px;
    text-transform: capitalize !important;
    border-radius: 4px !important;
    color: #fff !important;
    text-align: center !important;
    background-color: #D9001D !important;
    border: 1px solid #D9001D !important;

    &:hover {
        background-color: #D9001D !important;
    }
}

.error_container {
    width: 100%;
    height: 27px;
}

.error_message {
    font-size: 12px !important;
    line-height: 15px;
    color: #D9001D;
}

.img_error_container {
    margin-top: 1rem;
    width: 100%;
    height: 27px;
    padding-left: 1rem;
}

.img_error_message {
    font-size: 12px !important;
    line-height: 15px;
    color: #D9001D;
}

.img_icon{
    height: 20px;
    width: 24px;
}
@media screen and (max-width: 566px) {
    .edit_box_container {
        flex-direction: column;
    }
    .edit_text_container{
        padding-left: 0px;
        padding-bottom: 1rem;
    }
    .box_container{
        border: none;
    }
    .text_container {
        flex-direction: column;
        gap: 0rem;
        padding: 0rem;
    }
    .inner_text_container {
        width: 100%;
    }
    .img_error_container {
        margin-top: 0.5rem;
        height: 10px;
    }
    .img_inner_container {
        height: 80px;
        width: 80px;
        padding-left: 0px;
    }

    .userimg {
        height: 80px;
        width: 80px !important;
    }

    .edit_icon_container{
        height: 15px;
        width: 15px;
        padding: 0.6rem;
        left: 37%;
    }

    .button_container {
        margin: 0rem;
        margin-top: 0rem;
        width: 100%;
        flex-direction: column;
    }
    .cancel_btn{
        width: 100%;
    }
    .save_btn{
        width: 100%;
        margin-top: 1rem !important;
    }
    .edit_container {
        width: 100%;
        margin-top: 2rem;
        margin-left: 0px;
    }
    .img_icon{
        height: 13px !important;
        width: 16px !important;
    }
    .img_container{
        padding: 0px;
    }
}