.outer_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    height: 70vh;
}
.img_container{
    width: 85px;
    height: 82.97px;
}
.text_container{
    margin-top: 1rem;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #A8A8A8;
}
@media screen and (max-width: 566px){
    .img_container{
        height: 67px;
        width: 67px;
    }
    .text_container{
        font-weight: 400;
        font-size: 18px;
    }
}