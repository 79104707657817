.employee_list_container {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #383838;
  position: sticky;
}

@media screen and (max-width: 566px) {
  .employee_list_container {
    display: none;
  }
}
