@import "./variables.scss";

html,
body {
  background-color: $background-color;
}
.css-6hp17o-MuiList-root-MuiMenu-list{
  max-height: 40vh;
  overflow-y: scroll;
}
.css-r8u8y9 {
  max-height: 40vh !important;
  overflow-y: scroll !important;
}
::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #777776;
}
::-webkit-scrollbar-thumb {
  background: #eef3f9;
}

::-webkit-scrollbar-track {
  background: #fbfcfe;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #777776;
}
.css-13cymwt-control:hover{
  border-color: #D9001D !important;
}
.css-t3ipsp-control{
  border-color: #D9001D !important;
  box-shadow: none !important;
}
.css-t3ipsp-control:hover {
  border-color: #D9001D !important;
}
.css-epd502 {
  box-shadow: 0px 4px 16px rgb(185, 175, 175) !important;
  border: 1px solid rgb(185, 175, 175) !important;
  border-radius: 8px !important;
}
