.sidebar_logo {
    width: 25.6px !important;
    height: 36.41px !important;
  }
  
  .topbar_notification {
    width: 32px !important;
    height: 32px !important;
    object-fit: none !important;
    margin-right: 0.5rem;
    cursor: pointer;
    z-index: 0;
  }
  
  .logout_icon{
    width: 32px !important;
    height: 32px !important;
    object-fit: none !important;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  
  .sidebar_logo_container {
    cursor: pointer;
    position: absolute;
    color: white;
    top: 25px;
    left: 45px;
  }
  
  .sidebar_logo_main_heading {
    font-family: "Mulish" !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    color: #ffffff !important;
    height: 20px !important;
    width: 72px !important;
  }
  
  .sidebar_logo_subheading {
    font-family: "Mulish" !important;
    font-size: 8px !important;
    font-weight: 400 !important;
    color: "#FFFFFF" !important;
    height: 10px !important;
    width: 100% !important;
    line-height: 20px !important;
  }
  
  .sidebar_divider_style {
    margin-top: 1.5rem !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  
  .topbar_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px !important;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 37px !important;
  
  
  }
  
  .topbar_button_heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .topbar_button_abbribation {
    background-color: #D9001D !important;
    height: 36px !important;
    width: 36px !important;
    text-align: center !important;
    color: white !important;
    border-radius: 50px !important;
    padding-top: 8px;
  }
  
  .profilebox {
    border-radius: 50px !important;
    height: 36px !important;
    width: 36px !important;
    margin-left: 7px;
  }
  
  .userimg {
    border-radius: 50px !important;
    height: 36px !important;
    width: 36px !important;
  }
  
  .topbar_button_abbribation {
    margin-left: 5px;
  }
  
  .menu_style {
    border: 1px solid #F1F1F1 !important;
    width: 180px;
    border-radius: 18px;
  
  }
  
  .navHide {
    transform: translateY(-100%);
  }
  
  
  .logout_box{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .header_appbar{
    background-color: transparent !important;
  }
  
  .notification_count{
    position: absolute;
    height: 1rem;
    font-size: 8px;
    border-radius: 5px;
    padding: 3px;
    right: 9px;
    // right: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D9001D;
  }
  
  .header_box{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (max-width: 566px){
    .topbar_button_heading{
      font-size: 12px !important;
    }
  
    
  
    .profilebox{
      height: 28px !important;
      width: 28px !important;
    }
  
    .userimg{
      height: 28px !important;
      width: 28px !important;
    }
    .menu_style {
      width: 160px;
      padding: 0.4rem;
      height: 7.5rem !important;
    }
    .listStyle{
      font-size: 12px !important;
      margin-top: -0.8rem !important;
      margin-bottom: 0.2rem !important;
    }
    .listDivider{
      margin-top: -0.5rem !important;
    }
  
    .logout_icon {
      width: 16px !important;
      height: 16px !important;
      
    }
      .header_appbar{
        background-color: #fff !important;
      }
    
  }