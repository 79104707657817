.box_container {
    align-items: center;
    padding: 5px 0px;
    position: static;
    width: 100%;
    left: 0px;
    top: 64px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 24px 0px;
    max-height: 75vh;
    overflow-y: auto;
    -ms-overflow-style: none;
  scrollbar-width: none;
}
.box_container::-webkit-scrollbar {
    display: none;
}
.edit_text_container {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #383838;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
}

.edit_box_container {
    display: flex;
}
.inputbox_inner_container{
    width: 30%;
}
.text_container {
    display: flex;
    padding: 1rem;
    gap: 2rem;
    width: 100%;
}

.edit_container {
    // margin-left: 2rem;
    width: 100%;
}

.heading_container {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #383838;
}

.text_field_container {
    margin-top: 1rem;
}

.sub_container {
    margin-top: 0.5rem;
}

.text_field {
    height: 34px !important;
    width: 100%;
    // min-width: 23rem;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #383838 !important;
}

.button_container {
    margin: 1rem;
    margin-top: 2rem;
    display: flex;
}
.cancel_btn {
    width: 90px;
    text-transform: capitalize !important;
    border-radius: 4px !important;
    color: #D9001D !important;
    text-align: center !important;
    background-color: #fff !important;
    border: 1px solid #D9001D !important;

    &:hover {
        background-color: #fff !important;
    }
}

.save_btn {
    width: 90px;
    text-transform: capitalize !important;
    border-radius: 4px !important;
    color: #fff !important;
    text-align: center !important;
    background-color: #D9001D !important;
    border: 1px solid #D9001D !important;
    &:hover {
        background-color: #D9001D !important;
    }

    margin-left: 1rem !important;
}

.dissble_submit_button {
    width: 90px;
    text-transform: capitalize !important;
    border-radius: 4px !important;
    text-align: center !important;
    border: 1px solid #A8A8A8 !important;
    background-color: #A8A8A8 !important;
    color: #fff !important;
    margin-left: 1rem !important;
}

.error_container {
    width: 100%;
    // height: 27px;
}

.bullet_point_text_typography {
    color: #A8A8A8;
}

.error_message {
    font-size: 12px !important;
    line-height: 15px;
    color: #D9001D;
}

.img_error_container {
    margin-top: 1rem;
    width: 100%;
    height: 27px;
    padding-left: 1rem;
}

.img_error_message {
    font-size: 12px !important;
    line-height: 15px;
    color: #D9001D;
}

.error_message_container {
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
}

.bullet_point {
    margin: 0.5rem 0.5rem 1rem 0rem;
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #A8A8A8;
}

.bullet_container {
    display: flex;
    margin-top: 1rem;
}

@media screen and (max-width: 566px) {
    .box_container {
        border: none;
    }
    .text_container {
        flex-direction: column;
        gap : 1.5rem;
        padding: 0px;
    }
    .inputbox_inner_container{
        width: 100%;
    }
    .button_container {
        margin: 0px;
        width: 100%;
        flex-direction: column;
        margin-top: 2rem;
    }
    .cancel_btn{
        width: 100% !important;
    }

    .save_btn{
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 1rem !important;
    }
    .dissble_submit_button{
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 1rem !important;
    }

    .cancel_btn_box{
        width: 100% !important;
    }
    .submit_btn_box{
        width: 100% !important;
    }
}