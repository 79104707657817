.box_container {
    align-items: center;
    position: static;
    width: 100%;
    left: 0px;
    top: 64px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-bottom: 24px;
    max-height: 75vh;
    overflow-y: auto;
    -ms-overflow-style: none;
  scrollbar-width: none;
    // margin: 24px 0px; 
}
.box_container::-webkit-scrollbar {
    display: none;
}
.userimg {
    height: 130px;
    width: 130px !important;
    border-radius: 8px;
}

.full_container {
    width: 102%;
    height: 80vh;
    // overflow-y: scroll;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.full_container::-webkit-scrollbar {
    display: none;
}

.name_container {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem 1rem 1.5rem;

}

.username_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #383838;
}

.edit_container {
    display: flex;

}

.edit_icon {
    height: 18px;
    width: 18px;
    margin-right: 1rem;
    margin-top: -0.3rem;
}

.content_container {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    gap: 2rem;
}

.heading_text {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #383838;
}

.inner_wrapper {
    display: flex;
    padding: 1.5rem;
    gap: 2rem;
}

.name_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #383838;
    margin-top: 0.8rem;
    overflow: hidden;
    // width: 20%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.sub_container {
    margin-top: 2rem;
}

.img_container {
    margin-right: 1rem;
    width: 130px;
    height: 130px;
}

@media screen and (max-width: 566px) {
    .box_container {
        border: none;
    }
    .inner_wrapper {
        flex-direction: column;
    }

    .content_container {
        grid-template-columns: repeat(2, 1fr);
    }

    .img_container {
        width: 80px;
        height: 80px;
    }

    .userimg {
        width: 80px !important;
        height: 80px;
    }
    .inner_wrapper {
        padding: 0px;
        margin-top: 1rem;
        gap: 2rem !important;
    }
    .name_container{
        padding: 0px;
    }
    .divider_container{
        margin-top: 1rem;
    }
    .content_container{
        gap: 1rem;
    }
}