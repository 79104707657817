.card_container {
    padding: 32px 32px 48px;
    height: 184px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
    border-left: 5px solid #1976d2;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}

.heading_container {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color:#727372;;
    margin-left: 1rem;
    margin-bottom: 1rem;
}

.count_container {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #1976d2;
    margin-top: 1rem;
}

.vertical_card_container {
    padding: 35px 45px 24px 45px;;
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
    border-left: 5px solid #1976d2;
    box-sizing: border-box;
    border-radius: 8px;
    row-gap: 1rem;
    column-gap: 4rem;
    // cursor: pointer;

}
.icon_name_box{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}
.vertical_counter {
    font-weight: 600;
    font-size: 18px;
    color: #1976d2;
}

.vertical_heading_container {
    font-weight: 600;
    font-size: 18px;
    color: #727372;
}

.box_container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1.5px solid #abadab;
    cursor: pointer;
}
.border_box{
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    border-bottom: none;
    cursor: pointer;
}
.data_container {
    display: flex;
}

// table
.table_box_container {
    width: 100% !important;
    overflow: hidden;
}

.table_head_cell {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: #A8A8A8 !important;
}

.paper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    vertical-align: middle !important;
    height: 345px !important;
    width: 100% !important;
    color: #A8A8A8 !important;
    margin-left: 250px !important;
}

.table_cell {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.table_row {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #383838;
}

@media screen and (max-width: 1026px) {
    .vertical_heading_container {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        margin-left: 1rem;
        width: 100%;
    }

    .vertical_counter {
        font-weight: 700;
        font-size: 16px !important;
        line-height: 14px;
    }
}

@media screen and (max-width: 566px) {
    .card_container {
        border: none;
        padding: 0px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        height: 24px;
    }

    .vertical_card_container {
        border: none;
        padding: 0px;
        margin-top: -0.4rem;
        height: 100%;
    }

    .dividerLine {
        display: none;
    }

    .box_container {
        display: flex;
        justify-content: space-between;
    }

    .count_container {
        margin-top: 0px;
        font-size: 20px;
    }

    .box_content {
        display: flex;
    }

    .vertical_heading_container {
        margin-left: 1rem;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
    }

    .vertical_counter {
        font-weight: 600;
        font-size: 20px !important;
        line-height: 18px;
    }
}