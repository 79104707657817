.outer_container {
  margin-left: 1.5rem;
  margin-top: -5rem;
}
.scrollable_container {
  overflow-y: scroll;
  height: 100vH;
  margin-left: 2rem;
}

// responsivnes---------------------------------------------------

@media screen and (max-width: 566px) {
  .outer_container {
    margin-left: 0rem;
  }
}
@media screen and (max-width: 1026px) {
  .outer_container {
    margin-left: 0rem;
  }
}
