.outer_container{
    margin-left: 1.5rem;
    margin-top: -5rem;
}

@media screen and (max-width: 895px){
    .outer_container{
        margin-top: -4rem;
        margin-left: 0px;
    }
}

@media screen and (max-width: 566px){
    .outer_container{
        margin-top: -4rem;
        margin-left: 0px;
    }
}