.outer_container {
  max-width: 30rem;
}
.main_heading {
  vertical-align: top;
  color: #383838;
}
.sub_heading {
  vertical-align: top;
  margin-top: 1.5rem !important;
  color: #9d9d9d;
}

.success_button {
  width: 158px;
  height: 52px;
  top: 20px;
  text-align: center;
  &:hover {
    background-color: #d9001d !important;
  }
}

.resend_button {
  width: 158px;
  height: 52px;
  top: 20px;
  text-align: center;
  background-color: #9d9d9d !important;
  color: #fff !important;
}

.inline_styling {
  font-weight: 700;
  color: #383838;
}
.login_button {
  width: 115px;
  height: 52px;
  top: 20px;
  text-align: center;
  &:hover {
    background-color: #d9001d !important;
  }
}

// responsivnes---------------------------------------------------

@media screen and (max-width: 566px) {
  .main_heading {
    font-size: 24px !important;
  }

  .sub_heading {
    font-size: 16px !important;
  }

  .resend_button {
    width: 100%;
    font-size: 14px !important;
  }
  .success_button {
    width: 100%;
    font-size: 14px !important;
  }

  .login_button {
    width: 100%;
    font-size: 14px !important;
  }
}
