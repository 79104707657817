.outer_container{
    max-width: 30rem;
}
.main_heading {
    vertical-align: top;
    color: #383838;
}
.sub_heading{
  vertical-align: top ;
  margin-top: 1.5rem !important;
  color: #9D9D9D;
}     
.sub_heading_text{
  vertical-align: top ;
  margin-top: 0.5rem !important;
  color: #9D9D9D;
} 
.form_container {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
}  
.form_heading{
    align-items: left;
    margin-top: 1.5rem  !important;
    vertical-align: top;
    color: #444444;
}   
.input_field{
    height: 50px;
    margin-top: 1.5rem;
    max-width: 30rem !important;
    min-width: 15rem !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #A8A8A8;
    outline: none;
}
.text_error{
    height: 2.5rem;
    margin-top: 6px;
  }
.error_message{
    color: #D9001D;
    font-weight: 400 !important;
    font-size: 12px  !important;
    line-height: 15px !important;
} 
.submit_button{
    width: 127px;
    height: 52px;
    text-align: center;
    &:hover{
        background-color: #D9001D !important;
    }
}

.login_button{
    margin-left: 10px !important;
    width: 127px;
    height: 52px;
    text-align: center;
    border: 1px solid #D9001D !important;
    color: #D9001D !important;
    background-color: #fff !important;
    &:hover{
        background-color: #fff !important;
    }
}

// responsivnes---------------------------------------------------

@media screen and (max-width: 566px){
    .main_heading{
      font-size: 24px !important;
    }
  
    .sub_heading{
      font-size: 16px !important;
    }
  
    .form_heading{
      font-size: 14px !important;
    }
    .input_field{
      font-size: 12px !important;
    }
  
    .error_message{
      font-size: 12px !important;
    }
  
    .inputfield{
      font-size: 12px !important;
    }
    .button_box{
        display: flex;
        flex-direction: column;
        margin-top: 10%;
        // justify-content: space-between;
    }

    .submit_button{
        font-size: 14px !important;
        width: 100% !important;
    }

    .login_button{
        font-size: 14px !important;
        width: 100% !important;
        margin-top: 1rem !important;
        margin-left: 0px  !important;
    }
  }