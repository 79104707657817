.table_box_container {
  width: 100% !important;
  overflow: hidden;
}

.table_head_cell {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #a8a8a8 !important;
}

.paper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  vertical-align: middle !important;
  height: 345px !important;
  width: 100% !important;
  color: #a8a8a8 !important;
  margin-left: 250px !important;
}

.ticketId_text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.pending_no_data {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #9d9d9d;
}

.table_cell {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.table_row {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #383838;
  cursor: pointer;
}

@media screen and (max-width: 566px) {
  .table_head_cell {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
  }
  .table_cell {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .ticketId_text {
    font-size: 12px;
  }
}
