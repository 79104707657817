.project_box{
    font-weight: 700 !important;
    font-size: 25px !important;
    line-height: 20px !important;
    color: #383838;
  }
  .project_name{
    font-weight: 700 !important;
    font-size: 25px !important;
    line-height: 20px !important;
    color: #d9001d;
    margin-left: 0.4rem;
    padding-bottom: 0.4rem;
    border-bottom: 2px solid #d9001d;
  }
  .first_btn{
    padding: 12px 16px !important;
    margin-left: 1rem !important;
    text-transform: capitalize !important;
    border-radius: 4px !important;
    color: #fff !important;
    text-align: center !important;
    background-color: #d9001d !important;
    border: 1px solid #d9001d !important;
  
    &:hover {
      background-color: #d9001d !important;
    }
  }
  .second_btn{
    padding: 12px 16px !important;
    text-transform: capitalize !important;
    border-radius: 4px !important;
    color: #d9001d !important;
    text-align: center !important;
    background-color: #fff !important;
    border: 1px solid #d9001d !important;
    &:hover {
      background-color: #fff !important;
    }
  }