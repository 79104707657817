.main_heading {
  vertical-align: top;
  color: #383838;
}
.sub_heading {
  vertical-align: top;
  margin-top: 0.5rem !important;
  color: #9d9d9d;
}

.form_container {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}
.textField_container {
  margin-top: 1.5rem;
}
.text_error {
  height: 2.5rem;
  width: 460px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.inputlabel {
  font-weight: 400 !important;
  font-style: normal;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: left;
  margin-top: 1.5rem !important;
  vertical-align: top;
  color: #444444;
}

.inputfield {
  max-width: 30rem;
  margin-top: 1rem;
  outline: none;
  margin-bottom: 6px;
}

.text_danger {
  color: #d9001d;
}
.submit_container {
  margin-top: 1.5rem;
}
.submit_button {
  height: 52px;
  text-align: center;
}
.forgot_password_label {
  font-family: "mulish" !important;
  font-weight: "700" !important;
  font-size: 14px !important;
  line-height: 14px !important;
  text-align: right !important;
  color: "#383838" !important;
  max-width: 546px !important;
  cursor: "pointer" !important;
}

// responsivnes---------------------------------------------------

@media screen and (max-width: 566px) {
  .main_heading {
    font-size: 24px !important;
  }

  .sub_heading {
    font-size: 16px !important;
  }

  .inputlabel {
    font-size: 14px !important;
  }

  .text_error {
    font-size: 12px !important;
  }

  .inputfield {
    font-size: 12px !important;
  }

  .submit_button {
    width: 100%;
  }
  .submit_container {
    margin-top: 12%;
  }
}
