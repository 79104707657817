.issue_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px !important;
  width: 200px;
  background: #d9001d;
  border-radius: 4px !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  &:hover {
    background-color: #d9001d !important;
  }
}

.cancel_btn {
  padding: 12px 44px !important;
  // width: 160px !important;
  border: 1px solid #d9001d !important;
  border-radius: 4px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: center;
  letter-spacing: 0.15px !important;
  color: #d9001d !important;
  text-transform: capitalize !important;
  margin-right: 1rem !important;
}
.form_heading {
  align-items: left;
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important;
  vertical-align: top;
  color: #444444;
}
.top_heading {
  font-size: 25px !important;
}
.bottom_form_heading {
  align-items: left;
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important;
  vertical-align: top;
  color: #444444;
}

.input_field_description {
  padding: 13px 15px 13px 15px !important;
  max-width: 100% !important;
  height: 37px !important;
  // min-width: 400px !important;
  border-radius: 4px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px;
  text-align: center;
  color: #383838;
}

.create_ticket_btn {
  padding: 12px 16px !important;
  // width: 160px !important;
  background: #d9001d !important;
  border-radius: 4px;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.15px !important;
  text-transform: capitalize !important;
}

.button_container {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.multiSelect_dropdown {
  margin-top: 20px;
}

@media screen and (max-width: 1026px) {
  .issue_button {
    width: 100% !important;
  }
}
