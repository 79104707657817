.filter_container{
    position: static;
    width: 600px;
    left: 0px;
    top: 40px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}
.filter_inner_container{
  padding: 1rem;
  overflow: hidden;
}
.filter_text_container{
   display: flex;
   justify-content: space-between;
}
.inner_text_container {
    display: flex;
}
.text_field_container {
    width: 50%;
    padding: 10px;

}
.heading_container{
    margin-bottom: 0.8rem;
}
.filter_search {
    margin-top: 10px;
    width: 100%;
    text-align: end;
}

.filter_filterby{
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}
.filter_close_all{
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    margin-top: -0.5rem !important;
    margin-right: -0.5rem !important;
}
.filter_select_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.filter_status_container{
   width: 47%;
}
.filter_department_container{
   width: 47%;
}
.active_container{
    margin: 0.8rem;
    width: 246px;
    height: 120px;
    background: #FFFFFF;
    border-radius: 4px;
}
.active_select_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.active_select_inner_container{
    width: 100%;
}
@media screen and (max-width: 566px){
    .filter_container{
        width: 230px;
        height: 170px;
    }
    .filter_select_container{
        flex-direction: column;
    }
    .filter_status_container{
        width: 100%;
    }
    .filter_department_container{
        width: 100%;
    }
}