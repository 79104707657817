
.h2_header{
  font-weight: bold !important;
  font-size: 50px !important;
  line-height: 63px !important;
  text-align: left;
  vertical-align: top;
  color: #383838;
}

.form_container{
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.inputlabel{
  font-weight: 400 !important; 
  font-style: normal;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: left;
  margin-top: 2.5rem !important;
  vertical-align: top;
  color: #444444;
}

.inputfield{
    max-width: 30rem;
    height: 46px;
    margin-top: 1rem;
    outline: none;  
}
.text_error{
  margin-top: 6px;
  height: 2.5rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.text_danger{
  color : #D9001D
}

.submit_button{
  height: 52px;
  top: 20px;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center;
  border-radius: 4px !important;
  padding: 16px 32px 16px 32px !important;
  background-color: #d41932 !important;
}

.validation_point{
  width: 400px !important;
  margin-top: 0.5rem;
}

.box_bulletpoint{
  width: 30rem;
  display: flex;
}

.bullet_point_text{
  width: 30rem;
  margin-top: 0.3rem;
}
.bullet_point_grid{
  font-size: 14px;
  font-weight: 400 !important;
}

.bullet_point_text_typography{
  width: 30rem;
}
.bullet_point{
  margin: 0.5rem 0.5rem 1rem 0rem;
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #A8A8A8;
}
.button_container{
  margin-top: -1rem;
}
.dissble_submit_button{
  height: 52px;
  top: 20px;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center;
  border-radius: 4px !important;
  padding: 16px 32px 16px 32px !important;
  background-color: #A8A8A8 !important;
  color: #fff !important;
}

@media screen and (max-width: 566px){
  .h2_header{
    font-size: 24px !important;
  }

  .inputlabel{
      font-size: 14px !important;
      margin-top: 1.5rem !important;
  }

  .bullet_point_text_typography{
      font-size: 12px !important;
  }

  .inputfield{
      font-size: 12px !important;
  }
  .button_container{
      margin-top: 12%;
  }
  .submit_button{
      width: 100%;
  }

  .dissble_submit_button{
      width: 100%;
  }
}