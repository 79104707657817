.full_outer_wrapper {
  height: 95vh;
  padding: 1rem;
}
.outer_wrapper {
  overflow: hidden !important;
  max-height: 96vh !important;
  // position: fixed !important;
}

.outer_container {
  position: relative;
  min-height: 23rem;
  height: 96vh;
  box-sizing: border-box;
  min-width: 10rem;
  background-image: url("../assets/images/backgrounds/nickelfox_sdimg.png");
  background-size: cover;
  object-fit: cover;
  background-position: bottom;
  border-radius: 15px;
}
.logo_container {
  position: absolute;
  display: flex !important;
  top: 4rem;
  left: 4rem;
}
.logoImg {
  height: 44px !important;
  width: 31px !important;
}
.logo_main_heading {
  font-weight: 700;
  font-family: "mulish";
  font-size: 26px;
  line-height: 33px;
  color: #ffffff;
}
.logo_subheading {
  font-weight: 400;
  font-family: "mulish";
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.inner_container {
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  z-index: 1000;
}
.logo_text_container {
  margin-left: 1rem;
  margin-top: -5px;
}
.header_text_container {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: left;
  align-items: center;
  color: #fff;
  backface-visibility: hidden;
  height: 100%;
  width: 98%;
  // padding: 2rem;
}
.heading_primary_main {
  display: inline-block;
  font-family: "mulish";
  font-weight: 600 !important;
  font-size: 40px !important;
  line-height: 58px !important;
  width: 77%;
}

.heading_primary_sub {
  display: inline-block;
  font-weight: 500 !important;
  font-size: 25px !important;
  font-family: "mulish" !important;
  line-height: 43px !important;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 1rem !important;
  width: 77%;
}
.outer_content_wrapper {
  display: flex;
  justify-content: space-around;
}
.grid_body_container {
  width: 100%;
}
.content_container {
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  min-height: 85vh;
  height: 90vh;
  box-sizing: border-box;
  // height: 100vh;
  // position: fixed;
  border-radius: 8px;
  // min-width: 500px;
}
@media screen and (max-width: 1610px) {
  .heading_primary_main {
    font-weight: 600 !important;
    font-size: 40px !important;
    line-height: 54px !important;
  }
  .heading_primary_sub {
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 30px !important;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 1.5rem;
  }
  .logoImg {
    height: 35px !important;
    width: 28px !important;
  }
  .logo_main_heading {
    font-weight: 700 !important;
    font-family: "mulish";
    font-size: 23px !important;
    line-height: 20px !important;
    color: #ffffff;
  }
  .logo_subheading {
    font-weight: 400 !important;
    font-family: "mulish";
    font-size: 12px !important;
    line-height: 20px !important;
    color: #ffffff;
  }
  .inner_container {
    display: flex;
    position: absolute;
    top: 2rem;
    left: 2rem;
  }
  .logo_text_container {
    margin-left: 0.5rem;
    margin-top: 2px;
  }
}
@media screen and (max-width: 1465px) {
  .heading_primary_main {
    font-weight: 600 !important;
    font-size: 40px !important;
    line-height: 50px !important;
  }
  .heading_primary_sub {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 1.5rem;
  }
  .logoImg {
    height: 30px !important;
    width: 25px !important;
  }
  .logo_main_heading {
    font-weight: 700 !important;
    font-family: "mulish";
    font-size: 18px !important;
    line-height: 20px !important;
    color: #ffffff;
  }
  .logo_subheading {
    font-weight: 400 !important;
    font-family: "mulish";
    font-size: 9px !important;
    line-height: 20px !important;
    color: #ffffff;
  }
  .inner_container {
    display: flex;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }
  .logo_text_container {
    margin-left: 0.6rem;
    margin-top: -2px;
  }
}

// responsivnes---------------------------------------------------

@media screen and (max-width: 900px) {
  .outer_container {
    height: 0vh;
    min-height: 0;
    visibility: hidden;
  }

  .content_container {
    min-width: 20vh;
  }

  .mob_logoImg {
    height: 35px !important;
    width: 28px !important;
    margin: 20px 0px;
  }
}
@media screen and (max-width: 566px) {
  .full_outer_wrapper {
    padding: 1.5rem;
    height: 100vh;
  }
}
