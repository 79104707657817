.pagination_page_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.select_container {
    width: 100%;
    height: 2rem;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #383838;
}

@media screen and (max-width: 566px) {
    .pagination_container {
        flex-direction: column;
        gap: 10px;
    }
}