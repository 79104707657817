.success_container {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
}

.success_icon {
    height: 49px !important;
    width: 49px !important;
    margin-left: -11px;
}

.h2_header {
    font-weight: bold !important;
    font-size: 50px !important;
    line-height: 63px !important;
    text-align: left;
    vertical-align: top;
    color: #383838;
}

.h5_subtitle {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 32px !important;
    text-align: left;
    vertical-align: top;
    margin-top: 0.5rem !important;
    color: #9D9D9D;
}

.submit_button {
    height: 52px;
    top: 20px;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 4px !important;
    padding: 16px 32px 16px 32px !important;
    background-color: #d41932 !important;
}

@media screen and (max-width: 566px) {
    .h2_header {
        font-size: 24px !important;
    }

    .h5_subtitle {
        font-size: 16px !important;
    }

    .submit_button {
        width: 100% !important;
    }

}