.employee_list_container {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #383838;
}
.outer_container {
  margin-left: 1.5rem;
  margin-top: -5rem;
}
.ticketId_name{
  font-weight: 700;
  font-size: 15px;
  color: #383838;
}
.box_container {
  align-items: center;
  position: static;
  left: 0px;
  top: 100px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 1rem;
}
.box_container::-webkit-scrollbar {
  display: none;
}
.employee_list_search_container {
  display: flex;
  // height: 100px;
}
.search_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 1.5rem;
}
.employee_list_search_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_icon {
  margin-right: 1rem;
  // margin-top: 0.5rem;
  position: relative;
  background-color: #f3f3f3;
  height: 40px;
  width: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  // width: 28px;
}
.search_background_icon {
  margin-right: 1rem;
  // margin-top: 0.5rem;
  position: relative;
  height: 40px;
  width: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.search_dot {
  height: 6px;
  width: 6px;
  top: 0px;
  right: 0px;
  position: absolute;
  background: #d9001d;
  border-radius: 50%;
}

.filter_icon {
  margin-right: 1rem;
  // margin-top: 0.5rem;
  position: relative;
  background-color: #f3f3f3;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.filter_background_icon {
  margin-right: 1rem;
  // margin-top: 0.5rem;
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.filter_dot {
  height: 6px;
  width: 6px;
  top: 0px;
  right: 0px;
  position: absolute;
  background: #d9001d;
  border-radius: 50%;
}
.sub_container {
  width: 100%;
  padding: 0.7rem;
  display: flex;
  align-items: center;
  height: 80%;
}
.employees_text {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #383838;
}
// .table_container{
//     margin-top: -1.6rem;
// }
.employee_count {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.create_issue_btn {
  padding: 12px 16px !important;
  background: #d9001d !important;
  border-radius: 4px;
  width: 141px;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.15px !important;
  text-transform: capitalize !important;
}
.pagination_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 30px;
}
.nav_container {
  display: flex;
}
.date_range_container {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
}
.calendar_container {
  display: flex;
  margin-left: 1rem;
}
.from_date {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
}
.date_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 4px;
  // width: 120px;
  // height: 40px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-right: 0.7rem;
  margin-left: 0.7rem;
  margin-top: 0.4rem;
}
.to_date {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
}
.date_style {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
}
// responsivnes---------------------------------------------------

@media screen and (max-width: 566px) {
  .employees_text {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  }
  .outer_container {
    margin-left: 0rem;
  }
  .box_container {
    border: none;
  }
  .search_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 0;
    margin-right: 0rem;
  }
  .table_container {
    overflow-x: scroll;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .issue_button_container {
    display: none;
  }

  .sub_container {
    padding: 0rem;
  }

  .employee_list_search_container {
    height: 40px;
  }
  .mobile_search_box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;
  }
  // .calendar_container{
  //     display: none;
  // }
  .filter_background_icon {
    margin-right: 0px;
  }
  .search_background_icon {
    display: none !important;
  }
  .calendar_container {
    margin-left: 0px;
  }
  .mob_calendar {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
  }
  .from_date {
    font-size: 12px;
  }
  .to_date {
    font-size: 12px;
  }
  .date_style {
    font-size: 10px;
  }
  .date_box {
    padding: 4px;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .filter_dot {
    height: 6px;
    width: 6px;
    top: 4px;
    right: 2px;
  }

  .pagination_container {
    flex-direction: column;
    gap: 10px;
  }
}
@media screen and (max-width: 1026px) {
  .outer_container {
    margin-left: 0rem;
  }
  .table_container {
    margin-top: 1rem;
  }
}

.tabel_header{
  display: flex;
  align-items: center;
  margin-left: -1rem !important;
}
