
.box_container {
  align-items: center;
  padding: 0px 16px;
  margin: 16px 16px;
  position: static;
  width: 100%;
  left: 0px;
  top: 64px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
  max-height: 75vh;
  overflow-y: auto;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
}
// .box_container::-webkit-scrollbar {
//   display: none;
// }
.link_box {
  margin-top: 1.5rem;
}
.first_btn{
  padding: 12px 16px !important;
  text-transform: capitalize !important;
  border-radius: 4px !important;
  color: #fff !important;
  text-align: center !important;
  background-color: #d9001d !important;
  border: 1px solid #d9001d !important;

  &:hover {
    background-color: #d9001d !important;
  }
}
.second_btn{
  padding: 12px 16px !important;
  margin-left: 1rem !important;
  text-transform: capitalize !important;
  border-radius: 4px !important;
  color: #d9001d !important;
  text-align: center !important;
  background-color: #fff !important;
  border: 1px solid #d9001d !important;
  &:hover {
    background-color: #fff !important;
  }
}
.title_name{
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: #727372;
  margin-bottom: 0.6rem !important;
}
.value_name{
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #383838;
}
.project_box{
  font-weight: 700 !important;
  font-size: 25px !important;
  line-height: 20px !important;
  color: #383838;
}
.project_name{
  font-weight: 700 !important;
  font-size: 25px !important;
  line-height: 20px !important;
  color: #d9001d;
  margin-left: 0.4rem;
  padding-bottom: 0.4rem;
  border-bottom: 2px solid #d9001d;
}
.edit_text_container {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #383838;
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
}

.edit_box_container {
  display: flex;
}

.img_container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  position: relative;
}

.img_inner_container {
  height: 130px;
  width: 130px;
  padding-left: 0.5rem;
  position: relative;
}

.img_inner_container {
  height: 130px;
  width: 130px;
  padding-left: 0.5rem;
}

.userimg {
  height: 130px;
  width: 130px !important;
  border-radius: 8px;
}

.edit_icon_container {
  position: absolute;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.8rem;
  height: 30px;
  width: 30px;
  // bottom: 7%;
  top: 87%;
  left: 47%;
  border: 1px solid #e1e1e1;
}

.text_container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
}

.inner_text_container {
  width: 50%;
  height: 165px;
}
.content_name {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #383838;
}
.content_name:not(:last-child)::after {
  content: ", ";
}
.banner_container {
  padding: 0px 0 17px 20px;
  display: flex;
  justify-content: space-between;
}

.edit_container {
  width: 90%;
  margin-left: 2rem;
}

.heading_container {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
}

.text_field_container {
  margin-top: 1rem;
}

.text_field {
  height: 34px !important;
  // min-width: 23rem;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #383838 !important;
  width: 100%;
}

.resource_detail_container {
  margin: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}

.project_detail_heading {
  margin: 30px;
  font-weight: bold;
  font-size: 30px;
  color: #383838 !important;
}

.resource_detail {
  padding-bottom: 20px;
  width: 25%;
  p {
    font-weight: bold;
  }
}

.button_container {
  margin: 1rem;
  margin-top: -0.5rem;
  display: flex;
}

.cancel_btn {
  margin-right: 1rem;
  text-transform: capitalize !important;
  border-radius: 4px !important;
  color: #d9001d !important;
  text-align: center !important;
  background-color: #fff !important;
  border: 1px solid #d9001d !important;

  &:hover {
    background-color: #fff !important;
  }
}

.save_btn {
  width: 90px;
  text-transform: capitalize !important;
  border-radius: 4px !important;
  color: #fff !important;
  text-align: center !important;
  background-color: #d9001d !important;
  border: 1px solid #d9001d !important;

  &:hover {
    background-color: #d9001d !important;
  }
}
.img_error_container {
  margin-top: 1rem;
  width: 100%;
  height: 27px;
  padding-left: 1rem;
}

.img_error_message {
  font-size: 12px !important;
  line-height: 15px;
  color: #d9001d;
}

.img_icon {
  height: 20px;
  width: 24px;
}
